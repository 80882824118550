/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    h4: "h4",
    p: "p",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {Hero, Columns, Column, Background, List, Video, Testimonial, Cta} = _components;
  if (!Background) _missingMdxReference("Background", true);
  if (!Column) _missingMdxReference("Column", true);
  if (!Columns) _missingMdxReference("Columns", true);
  if (!Cta) _missingMdxReference("Cta", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!List) _missingMdxReference("List", true);
  if (!Testimonial) _missingMdxReference("Testimonial", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(Hero, {
    headline: "Customer Story: Hatch",
    text: "How Hatch uses Prismatic to build productized, highly-configurable integrations their customers can self-activate.",
    backgroundImage: "primary",
    background: "deepPurple",
    color: "white01",
    size: "xsmall"
  }), "\n", React.createElement(Columns, null, React.createElement(Column, {
    md: 4
  }, React.createElement(Background, {
    background: "gray03",
    color: "deepPurple",
    fullWidth: false
  }, React.createElement(_components.h3, {
    id: "key-results",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#key-results",
    "aria-label": "key results permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Key Results"), React.createElement(_components.h4, {
    id: "reduced-days-to-onboard",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reduced-days-to-onboard",
    "aria-label": "reduced days to onboard permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reduced days to onboard"), React.createElement(_components.h4, {
    id: "fewer-support-and-bug-tickets",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fewer-support-and-bug-tickets",
    "aria-label": "fewer support and bug tickets permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Fewer support and bug tickets"), React.createElement(_components.h4, {
    id: "increased-customer-satisfaction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#increased-customer-satisfaction",
    "aria-label": "increased customer satisfaction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Increased customer satisfaction"), React.createElement(_components.h4, {
    id: "regained-dev-time-for-core-product-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#regained-dev-time-for-core-product-work",
    "aria-label": "regained dev time for core product work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Regained dev time for core product work")), React.createElement(Background, {
    background: "gray03",
    color: "deepPurple",
    fullWidth: false
  }, React.createElement(_components.h3, {
    id: "about-hatch",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#about-hatch",
    "aria-label": "about hatch permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "About Hatch"), React.createElement(_components.p, null, "Hatch is a customer contact center platform that uses text messaging to help local businesses streamline their marketing, sales automation, and customer outreach."), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Industry:"), " Customer communications"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Size:"), " 60 employees"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Website:"), " ", React.createElement(_components.a, {
    href: "https://www.usehatchapp.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "usehatchapp.com"))), React.createElement(Background, {
    background: "gray03",
    color: "deepPurple",
    fullWidth: false
  }, React.createElement(_components.h3, {
    id: "why-prismatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-prismatic",
    "aria-label": "why prismatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Prismatic"), React.createElement(List, {
    variant: "check"
  }, React.createElement("li", null, "Enables reusable, highly-configurable integrations"), React.createElement("li", null, "Dev-friendly with full custom component support"), React.createElement("li", null, "Embedded marketplace lets customers self-serve")))), React.createElement(Column, {
    md: 8
  }, React.createElement(Video, {
    image: "/images/customers/adam-jacox-thumbnail.png",
    videoId: "821676785"
  }), React.createElement(_components.h2, {
    id: "challenge",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#challenge",
    "aria-label": "challenge permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Challenge"), React.createElement(_components.p, null, "When Adam Jacox joined Hatch as VP of Engineering, part of his role was ensuring their platform could scale to handle continued growth. Integrations stood out as an obvious bottleneck."), React.createElement(_components.p, null, "Hatch's platform relies heavily on data ingested from customers' CRMs and lead source solutions to build out marketing campaigns. The team had been coding bespoke integrations to meet each customer's needs."), React.createElement(Testimonial, {
    id: "hatch-1",
    color: "deepPurple"
  }), React.createElement(_components.p, null, "Maintaining all of those custom-built integrations was unmanageable. The integrations weren't reliable, and customers reported frequent problems. The team was spending 30-40% of their engineering capacity on integrations and still not keeping up."), React.createElement(_components.h2, {
    id: "solution",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#solution",
    "aria-label": "solution permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Solution"), React.createElement(_components.p, null, "Adam wanted a solution that would allow Hatch to deliver integrations without having to build everything from the ground up."), React.createElement(_components.p, null, "\"I wanted to approach it from the mindset of 'let's focus on our core product, what makes Hatch really special, and then let's try and outsource all the things that we don't need to do.' And I believed one of those things could be integrations,\" he said."), React.createElement(_components.p, null, "He and his engineering leaders compiled a list of embedded iPaaS solutions and began building proof of concept integrations."), React.createElement(_components.p, null, "\"One of the things that really set Prismatic apart from the competition was the ground up focus on embedded iPaaS,\" said Adam."), React.createElement(Testimonial, {
    id: "hatch-2",
    color: "deepPurple"
  }), React.createElement(_components.p, null, "They also liked Prismatic's dev-first approach, which gave them more control and flexibility. Their engineers could still write code when needed or build custom connectors to integrate with industry-specific apps like home services CRMs."), React.createElement(_components.p, null, "After selecting Prismatic, the team got to work moving their legacy integrations to Prismatic, this time as productized, configuration-driven integrations that could be reused across many customers. Then they embedded Prismatic's integration marketplace in the Hatch app so customers could self-serve."), React.createElement(_components.h2, {
    id: "results",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#results",
    "aria-label": "results permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Results"), React.createElement(_components.p, null, "When Hatch launched their integration marketplace and updated integrations, they saw an immediate improvement in reliability."), React.createElement(_components.p, null, "Customers were excited that they could now configure and self-activate their integrations in the embedded marketplace. This was faster and more accurate than waiting for the Hatch team to do the setup."), React.createElement(_components.p, null, "New customers now set up their own integrations during onboarding. This lets them configure their integrations to match their own needs, which means the marketing campaigns they run in Hatch using integration data are more successful. Days to onboard has decreased significantly."), React.createElement(_components.p, null, "Prismatic's management console gives the Hatch team – and their customers – better visibility into the data flowing through the integrations."), React.createElement(Testimonial, {
    id: "hatch-3",
    color: "deepPurple"
  }), React.createElement(_components.p, null, "All of this has led to fewer support tickets, fewer bug tickets, and increased customer satisfaction. Adam added, \"That has contributed to a better experience for our customers, which ultimately I believe helps Hatch retain those customers for longer.\""), React.createElement(_components.p, null, "The team has built around a dozen integrations on Prismatic and plans to build more in the coming months. They can now build integrations faster, and Prismatic has offloaded maintenance and scalability concerns. \"That has allowed us to then have engineers focus on other areas of the business, which we believe can deliver even more value,\" said Adam."), React.createElement(Cta, {
    title: "Inspired by what Hatch achieved?",
    copy: "Get a tailored demo to see how Prismatic can help your team deliver powerful native integrations, fast.",
    label: "Book a Demo",
    to: "https://prismatic.io/request-a-demo/"
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
